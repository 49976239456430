import domReady from '@wordpress/dom-ready';

domReady(() => {
  const blocks = document.querySelectorAll('.overlapped-media-text');

  if (0 < blocks.length) {
    function resizeBlocks() {
      if (991 < window.screen.width) {
        blocks.forEach((block) => {
          const contentWrapper = block.querySelector(
            '.overlapped-media-text__col--content'
          );
          const background = block.querySelector(
            '.overlapped-media-text__background'
          );
          const contentHeight = contentWrapper.clientHeight + 120 + 120;
          background.style.height = contentHeight + 'px';
        });
      }
    }

    resizeBlocks();

    window.addEventListener('resize', resizeBlocks);
  }
});
